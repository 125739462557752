<template>
    <yh-page>
        <div v-if="memberLoading" class="empty">
            加载中...
        </div>
        <div v-else-if="!memberLoading && !member" class="empty">
            暂无搭配师数据
        </div>
        <div v-else>

            <div class="user-info">

                <img :src="member.avatar" alt="">

                <div class="info">

                    <div class="member-name">
                        <span class="name">{{ member.name }}</span>
                        <span class="role">{{ roles[member.role] }}</span>
                    </div>

                    <div class="bd-no">帮搭号：{{ member.account }}</div>

                    <div class="collect-data">
                        {{ member.fans_num }}粉丝 · {{ member.collect_scheme_num }}收藏 · {{ member.scheme_num }}搭配
                    </div>

                    <div class="summary">
                        <div class="summary-title">个人简介</div>
                        <div class="summary-content">{{ member.summary || '这人很懒什么都没留下..' }}</div>
                    </div>
                </div>
            </div>

            <div class="content-header">搭配和收藏</div>

            <div class="collect-list">

                <div class="empty">
                    - 暂无数据 -
                </div>

            </div>


        </div>
    </yh-page>
</template>

<script>
    import YhPage from "../../../layout/YhPage";
    import api from "../../../repo/api";

    export default {
        name: "Detail",
        components: {YhPage},
        data() {
            return {
                member: null,
                account: '',
                memberLoading: true,
                roles: {
                    YH: '时尚人',
                    DR: '搭配达人',
                    BZ: '时尚博主',
                    DPS: '专业搭配师',
                }
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                this.account = this.$route.params.account;

                if (!this.account) this.memberLoading = false;

                this.getInfo()
            },

            getInfo() {
                this.memberLoading = true

                api.getMemberInfo(this.account, (data) => {
                    this.memberLoading = false

                    if (data.code === 0) this.member = data.data
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .empty {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        color: #333333;

        img {
            width: 48px;
            height: 48px;
        }
    }

    .user-info {
        display: flex;

        img {
            width: 320px;
            height: 320px;
            margin-right: 40px;
        }

        .info {
            flex: 1;
            margin-top: 8px;
        }
    }

    .member-name {
        display: flex;
        align-items: center;

        .name {
            font-size: 24px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 28px;
        }

        .role {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            padding: 2px 16px;
            border-radius: 12px;
            border: 1px solid #333333;
            margin-left: 24px;
        }
    }

    .bd-no {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 20px;
    }

    .collect-data {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-top: 16px;
    }

    .summary {
        margin-top: 32px;

        .summary-title {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
        }

        .summary-content {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin-top: 16px;
        }
    }

    .content-header {
        text-align: center;
        margin-top: 80px;
        margin-bottom: 125px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        position: relative;
        overflow: hidden;

        &:before, &:after {
            content: ' ';
            width: 50%;
            height: 1px;
            background-color: #EEEEEE;
            top: 11px;
            position: absolute;
        }

        &:before {
            left: -70px;
        }

        &:after {
            right: -70px;
        }
    }

</style>